import React from "react";
import { useEffect, useState } from "react";
import { FundraisingBarShimmer } from "./Util/Shimmer";

function addCommas(number) {
  if (typeof number !== "number") {
    throw new Error("Input must be a number.");
  }

  // Convert the number to a string
  let numberStr = number.toString();

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = numberStr.split(".");

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // // If there is a decimal part, add it back to the integer part with a dot
  // if (decimalPart) {
  //     numberStr = integerPart + "." + decimalPart;
  // } else {
  //     numberStr = integerPart;
  // }

  // // make it have two decimal places

  // // make it two decimal places unless it's a whole number, then make it have no decimals. If it has a decimal, make it two decimal places
  // if (numberStr.split('.')[1] == '00') {
  //     numberStr = numberStr.split('.')[0]
  // }
  // else if (numberStr.split('.')[1] != undefined) {
  //     if (numberStr.split('.')[1].length == 1) {
  //         numberStr = numberStr.split('.')[0] + '.' + numberStr.split('.')[1] + '0'
  //     }
  //     else {
  //         numberStr = numberStr.split('.')[0] + '.' + numberStr.split('.')[1].slice(0, 2)
  //     }
  // }

  return integerPart;
}

function FundraisingBar() {
  const [hostedPage, setHostedPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [elementID, setElementID] = useState("");
  const [elementName, setElementName] = useState("");
  const [goal, setGoal] = useState(10000000);
  const [raised, setRaised] = useState(500000);
  const [donations, setDonations] = useState(3);
  const [color, setColor] = useState("#66b089");
  const [maxWidth, setMaxWidth] = useState("FULLSCREEN");
  const [raisedOffset, setRaisedOffset] = useState(0);
  const [donationsOffset, setDonationsOffset] = useState(0);

  const [domID, setDomID] = useState("");

  const cssContentListener = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    let elementID = urlParams.get("elementID");
    if (
      !(event.data.action === "ADD_CSS_CONTENT" && event.data.id === elementID)
    )
      return;

    console.log("add css content fundraising bar", event.data);

    const cssContent = event.data.data;
    // inject css content into the iframe
    const style = document.createElement("style");
    style.innerHTML = cssContent;
    document.head.appendChild(style);
    console.log("css content iframe", cssContent);
  };

  // create an async useEffect to fetch elements config
  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    if (elementID == null) {
      elementID =
        process.env.REACT_APP_ENVIRONMENT == "development"
          ? "a43a8b4f-40aa-4fe7-becb-2b066c6759f3"
          : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    }
    setElementID(elementID);

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();

    if (data.elementType != "FUNDRAISING_BAR") {
      throw new Error("Wrong element type");
    }
    setActive(data.active);
    setGoal(data.goalAmount);
    setRaised(data.raised);
    setDonations(data.donations);
    setColor(data.color);
    setElementName(data.elementName);
    setMaxWidth(data.size);
    // the two below attributes may not exist. if they dont, default to 0
    setDonationsOffset(
      data.donationsOffset === undefined ? 0 : data.donationsOffset,
    );
    setRaisedOffset(data.raisedOffset === undefined ? 0 : data.raisedOffset);
    setLoading(false);
    return data;
  };

  useEffect(() => {
    resizeIframe();
    console.log("SENDING");

    if (loading === false && hostedPage !== null) {
      // send message that element has finished loading
      window.parent.postMessage(
        {
          action: "FINISHED_LOADING",
          entity: "CHARITYSTACK",
          sender: "FUNDRAISING_BAR",
          receiver: "HOSTED_PAGE",
        },
        "*",
      );
    }
  }, [loading]);

  useEffect(() => {
    let interval;
    const initiate = async () => {
      window.addEventListener("message", cssContentListener);
      // Initial fetchElementsConfig() call
      const data = await fetchElementsConfig();

      console.log("sending message that fundraising bar has finished loading");
      // send message that element has finished loading
      window.top.postMessage(
        {
          action: "IFRAME_LOADED",
          entity: "CHARITYSTACK",
          sender: "FUNDRAISING_BAR",
          receiver: "PARENT_WEBSITE",
          data: JSON.stringify(data),
        },
        "*",
      );
      // grab hostedPage boolean
      const urlParams = new URLSearchParams(window.location.search);
      const hostedPage = urlParams.get("page");
      // page can be either null, HOSTED_PAGE_FULLSCREEN, or HOSTED_PAGE_POPUP
      setHostedPage(hostedPage);
      setDomID(urlParams.get("domID"));

      // Call fetchElementsConfig() every 5 seconds to reload the data
      interval = setInterval(() => {
        fetchElementsConfig().catch((err) => console.log("ERROR", err));
      }, 5000);
    };
    initiate();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      window.removeEventListener("message", cssContentListener);
    };
  }, []);

  const resizeIframe = () => {
    const height = document.body.scrollHeight;
    const width = document.body.scrollWidth;

    // Send the content height to the parent window
    window.parent.postMessage(
      {
        action: "RESIZE",
        entity: "CHARITYSTACK",
        sender: "FUNDRAISING_BAR",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify({
          height: height,
          width: width,
          elementID: elementID,
          domID: domID,
        }),
      },
      "*",
    );
  };

  if (loading) {
    return (
      <>
        <div className="mx-auto h-full max-w-lg items-center px-2">
          <FundraisingBarShimmer />
        </div>
      </>
    );
  }
  if (!loading) {
    if (active) {
      return (
        <div data-name={elementName}>
          <div
            id={`${elementID}-CS-fundraising-bar-container`}
            className={`mx-auto h-full items-center px-[1px] py-2 text-xs ${
              maxWidth == "FULLSCREEN" || hostedPage !== null
                ? "max-w-full sm:text-lg"
                : "max-w-sm sm:text-sm"
            }`}
          >
            <div
              id={`${elementID}-CS-fundraising-bar-pill-text`}
              className={`flex justify-between ${
                elementID == "dd8990fb-6b29-4766-ba7c-90102de1f15e" // obat fundraising bar one-off white text
                  ? "text-white"
                  : "text-gray-500"
              }`}
            >
              <div
                id={`${elementID}-CS-fundraising-bar-pill-text-left`}
                className="flex pl-[1px]"
              >
                <div
                  id={`${elementID}-CS-fundraising-bar-pill-text-left-raised`}
                >
                  ${addCommas(raised + raisedOffset)} raised
                </div>
                <div
                  id={`${elementID}-CS-fundraising-bar-pill-text-left-separator`}
                  className="px-2 text-gray-300"
                >
                  |
                </div>
                <div
                  id={`${elementID}-CS-fundraising-bar-pill-text-left-donations`}
                >
                  {donations + donationsOffset} donations
                </div>
              </div>
              <div
                id={`${elementID}-CS-fundraising-bar-pill-text-right`}
                className="pr-[1px]"
              >
                ${addCommas(goal)} goal
              </div>
            </div>
            <div
              id={`${elementID}-CS-fundraising-bar-pill`}
              className={`h-4 w-full rounded-3xl bg-gray-200 ${
                maxWidth == "FULLSCREEN" || hostedPage !== null ? "sm:h-5" : ""
              }`}
            >
              {
                // only show colored bar if raised + raisedOffset is greater than 0 to prevent 5% width bar from showing for 0 raised
                raised + raisedOffset > 0 && (
                  <div
                    id={`${elementID}-CS-fundraising-bar-pill-colored`}
                    className={`h-full rounded-3xl`}
                    style={{
                      backgroundColor: color,
                      width: `clamp(5%, calc(${
                        raised + raisedOffset
                      }/${goal}*100%), 100%)`,
                    }}
                  ></div>
                )
              }
              {/* <div className='flex items-center justify-center -mt-[16px] sm:-mt-[18px] text-gray-600'>
                            {Math.round((raised / goal) * 100)}%
                        </div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {hostedPage !== null ? (
            <></>
          ) : (
            <div
              className={`h-18 mx-auto h-full items-center rounded-3xl border border-gray-200 px-[1px] py-2 text-center text-xs text-gray-500 ${
                maxWidth == "FULLSCREEN" || hostedPage !== null
                  ? "max-w-full sm:text-lg"
                  : "max-w-sm sm:text-sm"
              }`}
            >
              Deactivated Element: {elementName}
            </div>
          )}
        </>
      );
    }
  }
}

export default FundraisingBar;
